const config = {
  uiUrl: `${process.env.UI_URL}`,
  apiUrl: `${process.env.WORDPRESS_URL}/wp-json`,
  loginUrl: `${process.env.WORDPRESS_URL}/wp-admin`,
  graphqlUrl: `${process.env.WORDPRESS_URL}/graphql`,
  siteTitle: 'Asia Brewers Network',
  AUTH_TOKEN: '',
  USERNAME: '',
  gaId: process.env.GA_TRACKING_ID,
  postsPerPage: 10,
  paypalClientId: process.env.PAYPAL_CLIENT_ID!
};

export default config;
